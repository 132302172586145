:root {
  --bg-color: #fff;
  --text-color: cornflowerblue;
  --emphasis-text-color: cornflowerblue;
  --link-color: rgb(127, 156, 180);
  --emphasis-color: goldenrod;
  --card-border: goldenrod;
  --accent-text: goldenrod;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  position: relative;
  font-weight: normal;
}

body {
  min-height: 100vh;
  min-height: 100svh;
  background: var(--bg-color);
  color: var(--text-color);
  position: relative;
}

body, html {
  overflow-x: clip;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

h1 {
  letter-spacing: -1.5px;
  font-size: 2.5rem;
}

h2 {
  letter-spacing: -0.5px;
  margin-bottom: 1rem;
}

p {
  letter-spacing: 0.5px; 
  line-height: 1.5rem;
}

a {
  color: var(--link-color);
}
