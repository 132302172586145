#main-content {
  max-width: 768px;
  min-height: 80vh;
  margin: 0 auto;
  padding: 0 1rem 2rem 1rem;
}
#main-nav {
  padding: 1rem 1rem 0 1rem;
  position: sticky;
  width: 100vw;
  top: 0;
  z-index: 5;
  display: grid;
  grid-template-areas: 
    "logo"
    "links";
  gap: 1rem;
  background: var(--bg-color);
  box-shadow: 0 0 5px 5px white;
}
#main-nav .logo-home-link {
  grid-area: logo;
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 0.5rem;
}
#main-nav .logo-home-link .logo {
  height: 2rem;
  animation: spin 5s linear infinite;
}
@keyframes spin {
  from {
    transform: rotateY(0deg) perspective(100px);
  }
  to {
    transform: rotateY(360deg) perspective(100px);
  }
}
#main-nav .logo-home-link h1 {
  font-size: 2.2rem;
}
#main-nav ul {
  grid-area: links;
  font-size: 1.25rem;
  color: var(--text-color);
  display: flex;
  list-style: none;
  padding: 0;
}
#main-nav li {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
#main-nav a {
  color: var(--accent-text);
}
#main-nav a svg {
  vertical-align: middle; 
  margin-right: 0.25rem;
  will-change: transition;
  transition: transform 0.3s ease;
}
#main-nav a:hover, #main-nav a:focus {
  text-shadow: 0 0 1px;
}
#main-footer {
  margin: 0 auto;
  border-top: 1px solid rgb(188, 194, 199);
  padding: 1rem;
  max-width: 768px;
}
#main-footer h1, #main-footer p {
  margin-bottom: 2rem;
}
#main-footer svg {
  vertical-align: middle;
}
#main-footer .copyright {
  margin-top: 4rem; 
}
.hero {
  position: relative;
  min-height: 65vh;
  min-height: 65svh;
  max-height: 600px;
  display: grid;
  grid-template-rows: 1fr auto;
  justify-items: center;
  overflow: hidden;
}
.hero h2 {
  font-size: 2.5rem;
  letter-spacing: -1px;
}
.hero h2 span {
  display: inline-block;
  animation-name: slide;
  animation-fill-mode: forwards;
  transform: translateY(3rem);
  opacity: 0.5;
}
@keyframes slide {
  from {
    opacity: 0.5;
    transform: translateY(3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.accent-text {
  color: var(--accent-text);
}

h1, h2 {
  color: var(--emphasis-text-color);
}
h1, h2 {
  font-weight: 500;
}

.contact-info {
  margin: 1rem 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.contact-info h2 {
  font-size: 2rem;
}
.contact-info .contact-splash {
  margin: 2rem 0;
}
.sites-list {
  padding: 1rem 0;
}
.sites-list h2, .resume h2 {
  font-size: 2rem;
}
.sites-list ul {
  list-style: none;
  padding-left: 0;
}
.sites-list li {
  border-radius: 0.5rem;
  background: var(--bg-color);
  padding: 0.5rem 1rem 1rem 1rem;
  margin: 1rem 0;
  width: fit-content;
}
.sites-list li video {
  max-width: 100%;
}
.sites-list li:first-of-type {
  margin-top: 2.5rem;
  position: relative;
  border: none;
}
.sites-list li:first-of-type::before {
  font-style: italic;
  position: absolute;
  line-height: 2rem;
  top: -2.2rem;
  left: 1rem;
  color: var(--emphasis-color);
}
.sites-list li a {
  font-size: 1.75rem;
  letter-spacing: -0.5px;
  text-decoration: none;
  position: relative;
  line-height: 3rem;
}
.sites-list li a::after {
  content: "→";
  position: absolute;
  right: -2rem;
  bottom: 0rem;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: right;
  width: 2rem;
  transition: width 0.2s ease, right 0.2s ease;
}
.sites-list li a:hover:after {
  width: 2.4rem;
  right: -2.4rem;
}

.resume {
  margin-top: 2rem;
}
.resume ul {
  list-style: none;
  padding-left: 0;
}
.resume li {
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--card-border);
  padding: 1rem 1.5rem;
}
.resume li a {
  font-size: 1.3rem;
}
.resume li p {
  margin: 0;
}
.resume li .date {
  margin-bottom: 0.4rem;
  color: rgb(188, 194, 199);
}

@media screen and (min-width: 768px) {
  #main-nav {
    margin-bottom: 0.5rem;
    grid-template-areas: 
      "logo links";
    grid-template-columns: 1fr auto;
  }
  #main-nav .logo-home-link {
    gap: 1rem;
  }
  #main-nav .logo-home-link h1 {
    font-size: 3rem;
  }
  #main-nav .logo-home-link .logo {
    height: 3rem;
  }
  #main-nav ul {
    font-size: 1.5rem;
    gap: 1rem;
    margin-right: 1rem;
  }
  .hero {
    position: relative;
  }
  .hero h2 {
    padding: 0.5rem;
    position: absolute;
    left: 0;
    top: 7rem;
    z-index: 2;
    font-size: 5rem;
    order: 0;
  }
  .hero img {
    margin-left: 50%;
    height: 100%;
  }
  #main-content {
    max-width: 1200px;
    padding: 0 1rem;
    align-items: start;
  }
  #main-content .home-section {
    margin-top: 5rem;
  }
  #main-content .hero.desktop {
    display: block;
    position: sticky;
    top: 0;
  }
  #main-content .hero.mobile {
    display: none;
  }
  #main-content .contact-info, #main-content .resume {
    max-width: 768px;
    margin: auto;
  }
  #main-content .contact-info .contact-splash {
    margin: 2rem 15% 0 15%;
  }
  #main-content .contact-info {
    margin-bottom: 3rem;
  } 
}
